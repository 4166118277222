<template>
  <header v-if="purchase && product">
    <!-- Sport and duration -->
    <div class="d-flex justify-content-between">
      <div class="text-orange font-weight-bold">
        <template v-if="purchase.sport">
          {{ capitalize(purchase.sport?.internal_name) }}
        </template>
        <template v-if="purchase.sport && duration"> | </template>
        <template v-if="duration">{{ duration }}</template>
      </div>

      <b-dropdown
        v-if="purchase['@type'] === 'FieldRentalPurchase'"
        right
        no-caret
        lazy
        variant="no-border"
      >
        <template #button-content>
          <i class="far fa-ellipsis-vertical fa-lg" />
        </template>
        <b-dropdown-item
          v-if="canShare"
          @click="handleShare"
        >
          <i class="far fa-lg fa-share-nodes mr-1" />
          {{ capitalize(trans('general.share')) }}
        </b-dropdown-item>

        <b-dropdown-item
          v-if="icalLink"
          :href="icalLink"
        >
          <i class="far fa-lg fa-calendar-plus mr-1" />
          {{ trans('booking.confirmation.icalLink') }}
        </b-dropdown-item>

        <b-dropdown-divider v-if="cancelEverything || cancelForMe || canAskToBeSubstituted" />

        <b-dropdown-item
          v-if="canCancelEverything"
          variant="danger"
          @click="cancelEverything"
        >
          {{ trans('booking.default.cancel.cancelEverything.title') }}
          <small
            class="d-block text-grey"
            style="font-size: 0.6em"
          >
            {{ trans('booking.default.cancel.cancelEverything.subtitle') }}</small
          >
        </b-dropdown-item>

        <b-dropdown-item
          v-if="canCancelForMe"
          variant="danger"
          @click="cancelForMe"
        >
          {{ trans('booking.default.cancel.cancelForMe.title') }}
          <small
            class="d-block text-grey"
            style="font-size: 0.6em"
          >
            {{ trans('booking.default.cancel.cancelForMe.subtitle') }}</small
          >
        </b-dropdown-item>

        <b-dropdown-item
          v-else-if="canAskToBeSubstituted"
          variant="danger"
          @click="cancelForMe"
        >
          {{ trans('booking.default.cancel.askForSubstitution.title') }}
          <small
            class="d-block text-grey"
            style="font-size: 0.7em"
          >
            {{ trans('booking.default.cancel.askForSubstitution.subtitle') }}</small
          >
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- /Sport and duration -->

    <!-- Venue name and location -->
    <router-link
      v-slot="{ href }"
      custom
      :to="{ name: 'center', params: { centerId: product?.center.descriptions[0].slug } }"
    >
      <h2 class="h4 mb-1">
        <a
          :href="href"
          class="text-dark"
        >
          {{ product?.center.name }}
        </a>
      </h2>
    </router-link>

    <div class="text-gray-text fs-1 mb-3">
      <template v-if="product.center.address.zone">
        {{ capitalize(product.center.address.zone) }}
        <template v-if="product.center.address.parentZone">
          , {{ capitalize(product.center.address.parentZone) }}
        </template>
      </template>
      <template v-else-if="['card', 'virtual_credit'].includes(purchase.product_type)">
        {{ purchase.name }}
      </template>
      <template v-else-if="purchase.product_type === 'entrance'">
        {{ capitalize(purchase.name) }}
      </template>
    </div>
    <!-- /Venue name and location -->

    <!-- Datetime -->
    <div
      v-if="date && !['card', 'virtual_credit', 'subscription'].includes(purchase.product_type)"
      class="mt-2"
    >
      {{ capitalize(date) }} {{ `${df} - ${dt}` }}
    </div>
    <!-- /Datetime -->

    <hr class="mb-1" />

    <!-- Details -->
    <div class="row">
      <div class="col-6 col-lg-3 col-xxl-2 mt-2">
        <div class="text-muted">{{ capitalize(trans('booking.summary.price.label')) }}</div>
        <span
          :style="{
            textDecoration: purchase.refund_status === 'refunded' ? 'line-through' : 'initial',
          }"
        >
          <span v-if="showFullPrice">{{ intlCurrencyFormatter.format(purchase.amount) }}</span>
          <span v-else>{{ intlCurrencyFormatter.format(myAttribution.amount) }}</span>
        </span>
      </div>

      <div
        v-if="resources && resources.length"
        class="col-6 col-lg-3 col-xxl-2 mt-2"
      >
        <div class="text-muted">{{ capitalize(trans('booking.summary.resource.label')) }}</div>
        {{ resources.join(', ') }}
      </div>

      <div
        v-if="purchase.level_min"
        class="col-6 col-lg-3 col-xxl-2 mt-2"
      >
        <div class="text-muted">{{ capitalize(trans('general.level')) }}</div>
        {{ levelMinName }}
        <template v-if="purchase.level_max"> - {{ levelMaxName }} </template>
      </div>

      <div
        v-if="purchase.gender && purchase.gender !== 'mixed'"
        class="col-6 col-lg-3 col-xxl-2 mt-2"
      >
        <div class="text-muted">{{ capitalize(trans('general.gender')) }}</div>
        {{ trans(`form.genderType.${purchase.gender}`) }}
      </div>

      <div
        v-if="purchase.visibility && purchase.visibility === 'public'"
        class="col-6 col-lg-3 col-xxl-2 mt-2"
      >
        <div class="text-muted">{{ capitalize(trans('general.visibility')) }}</div>
        {{ capitalize(trans(purchase.visibility)) }}
      </div>
    </div>
    <!-- /Details -->
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import BookingMixin from '../../mixins/BookingMixin';
import Formatter from '../../../helpers/Formatter';
import DangerousAction from '../../mixins/DangerousAction';

export default {
  name: 'BookingSummary',
  mixins: [BookingMixin, DangerousAction],
  props: {
    product: {
      type: Object,
      required: false,
      default: undefined,
    },
    purchase: {
      type: Object,
      required: false,
      default: undefined,
    },
    showFullPrice: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters('booking', [
      'sport',
      'purchaseActivityStartFormatted',
      'purchaseActivityEndFormatted',
      'canCancelEverything',
      'canCancelForMe',
      'canAskToBeSubstituted',
    ]),
    duration() {
      if (this.purchase.date_from && this.purchase.date_to) {
        return Formatter.duration(
          (this.purchase.date_to.valueOf() - this.purchase.date_from.valueOf()) / 1000 / 60,
          'minutes'
        );
      }
      return null;
    },
    canShare() {
      return !!navigator.share;
    },
    icalLink() {
      return Routing.generate('website.booking.ical', { purchase: this.purchase.id });
    },
    resources() {
      if (this.product && this.purchase) {
        return this.purchase.resources.map((r) => {
          const resourceId = typeof r === 'object' ? r['@id'] : r;
          const resource = this.product.resources.find((pr) => pr['@id'] === resourceId);
          return resource ? resource.name : undefined;
        });
      }
      return [];
    },
    date() {
      return this.purchase?.date_from
        ? new Intl.DateTimeFormat(this.spflocale, {
            weekday: 'short',
            month: 'long',
            day: 'numeric',
          }).format(this.purchase.date_from)
        : null;
    },
    df() {
      return this.purchaseActivityStartFormatted;
    },
    dt() {
      return this.purchaseActivityEndFormatted;
    },
    levelMinName() {
      return this.purchase?.level_min?.descriptions?.find((l) => l.locale === this.spflocale)?.name;
    },
    levelMaxName() {
      return this.purchase?.level_max?.descriptions?.find((l) => l.locale === this.spflocale)?.name;
    },
    targetUrl() {
      return this.$router
        ? new URL(this.$router.resolve({ name: 'booking' }).href, window.location.origin).href
        : Routing.url('website.booking', { id: this.purchase.id });
    },
  },
  methods: {
    handleShare() {
      navigator
        .share({
          // title: this.trans('booking.share.title', {
          //   center: this.product.center.name,
          //   sport: this.purchase.sport?.internal_name ?? '',
          // }),
          text: this.trans('booking.share.text', {
            player: this.myAttribution.contact.name,
            center: this.product.center.name,
            sport: this.purchase.sport?.internal_name ?? '',
            status: this.trans(`booking.share.status.${this.purchase.status}`),
            visibility: this.trans('purchase.meta.visibility.' + this.purchase.visibility),
            date: this.date + ' ' + this.df,
            url: this.targetUrl,
          }),
          url: this.$router
            ? this.$router.resolve({ name: 'booking' }).href
            : Routing.generate('website.booking', { id: this.purchase.id }),
        })
        .then(() => {
          // @todo: Log event to GA ?
          console.debug('shared');
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.dir(error);
            console.error('Share error :', error);
          }
        });
    },
    async cancelEverything() {
      try {
        const baseKey = 'booking.default.cancel.confirm.cancelEverything';
        if (await this.confirmDangerousAction(this.trans(`${baseKey}`))) {
          await this.$store.dispatch('booking/cancelBooking', this.purchase?.id);
        }
      } catch (e) {
        // console.dir(e);
        // eslint-disable-next-line no-underscore-dangle
        this.toast(e.errors._error);
        // @todo message
      }
    },
    async cancelForMe() {
      // Confirm modal
      try {
        const baseKey = `booking.default.cancel.confirm.${this.canCancelForMe ? 'cancelForMe' : 'askForSubstitution'}`;
        if (await this.confirmDangerousAction(this.trans(`${baseKey}`))) {
          await this.$store.dispatch('booking/cancelFor', { purchaseId: this.purchase?.id });
          // @todo message
        }
      } catch (e) {
        // console.dir(e);
        // eslint-disable-next-line no-underscore-dangle
        this.toast(e.errors._error);
        // @todo message
      }
    },
  },
};
</script>
