<template>
  <booking-breadcrumbs-container>
    <!-- Most of the time we show 'bookings', but in wallet topup context it is confusing -->
    <a
      :href="firstBreadcrumbRoute"
      class="text-dark"
      style="gap: 5px"
    >
      <!-- <i class="far fa-sm fa-list-timeline text-orange" /> -->
      <span class="overflow-hidden text-ellipsis text-truncate">
        <template v-if="isInWalletTopupContext">{{
          capitalize(trans('general.wallets'))
        }}</template>
        <template v-else>{{ capitalize(trans('general.bookings')) }}</template>
      </span>
    </a>

    <i
      v-if="product"
      class="far fa-chevron-right px-2"
      style="line-height: normal"
    />
    <a
      v-if="product"
      :href="`${Routing.generate('spartan.website.center_show', { slug, _locale: spflocale })}`"
      class="text-truncate text-dark"
      style="gap: 5px"
    >
      {{ product.center.name }}
    </a>

    <i
      class="far fa-chevron-right px-2"
      style="line-height: normal"
    />
    <router-link
      v-if="hasRouter"
      v-slot="{ navigate }"
      custom
      :to="{ name: 'booking' }"
    >
      <a
        href="#"
        class="text-dark"
        style="gap: 5px"
        @click="navigate"
      >
        #{{ purchase ? purchase?.id : 'loading' }}
      </a>
    </router-link>

    <span
      v-else
      class="text-dark"
      style="gap: 5px"
    >
      #{{ purchase ? purchase?.id : 'loading' }}
    </span>

    <template v-if="hasRouter">
      <template v-for="(b, i) in $route.meta.bread">
        <i
          :key="`separator-${b.text}`"
          class="far fa-chevron-right px-2"
          style="line-height: normal"
        />
        <span
          :key="b.text"
          class=""
          style="gap: 5px"
          :class="i === $route.meta.bread.length - 1 ? 'font-weight-bold' : ''"
        >
          <i
            v-if="b.icon"
            :class="`fa fa-${b.icon}`"
          />
          <span
            style="white-space: pre; white-space: nowrap"
            class="overflow-hidden text-ellipsis"
          >
            {{ trans(b.text) }}</span
          >
        </span>
      </template>
    </template>

    <template v-else-if="lastBreadcrumbRoute">
      <i
        class="far fa-chevron-right px-2"
        style="line-height: normal"
      />

      <a
        :href="lastBreadcrumbRoute.href"
        class="text-dark"
        style="gap: 5px"
      >
        <!-- <i class="far fa-sm fa-list-timeline text-orange" /> -->
        <span class="overflow-hidden text-ellipsis text-truncate">
          {{ capitalize(lastBreadcrumbRoute.title) }}
        </span>
      </a>
    </template>
  </booking-breadcrumbs-container>
</template>

<script>
import BookingBreadcrumbsContainer from './BreadcrumbsContainer.vue';

export default {
  name: 'BookingBreadcrumbs',
  components: { BookingBreadcrumbsContainer },
  props: {
    firstBreadcrumbRoute: {
      type: String,
      required: true,
    },
    lastBreadcrumbRoute: {
      type: Object,
      required: false,
      default: undefined,
    },
    product: {
      type: Object,
      required: true,
    },
    purchase: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasRouter() {
      return !!this.$route;
    },
    Routing() {
      return Routing;
    },
    slug() {
      return window.SportFinder.store.center?.slug ?? null;
    },
    // @todo: Refactor
    isInWalletTopupContext() {
      return this.purchase['@type'] === 'VirtualCreditPurchase';
    },
  },
};
</script>
