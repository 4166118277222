<template>
  <div class="border-bottom">
    <div class="container p-2 p-sm-3 w-100">
      <div
        class="d-flex align-items-center overflow-hidden w-100 fs-2 fs-md-3"
        style="height: 1.5rem"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookingBreadcrumbsContainer',
};
</script>
